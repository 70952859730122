import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { NavLink } from "react-router-dom";
import axios from "axios";

const API_BASE_URL = "https://api-e5tzoauvmq-uc.a.run.app";

const Dashboard = () => {
  const { user } = useContext(UserContext); // Access user data from context
  const firstName = user && user.name ? user.name.split(" ")[0] : "Guest";

  // State for card counts
  const [collisionCount, setCollisionCount] = useState(0);
  const [ppeCount, setPpeCount] = useState(0);
  const [actionCount, setActionCount] = useState(0);
  // Fetch card data on component mount
  useEffect(() => {
    const fetchCounts = async () => {
      try {
        console.log("[Dashboard] Fetching counts...");

        // Fetch collisions count
        const collisionResponse = await axios.get(`${API_BASE_URL}/api/database/count-collisions`);
        console.log("[Dashboard] Collisions Response:", collisionResponse.data);
        setCollisionCount(collisionResponse.data.count);

        // Fetch PPE count (shared with Intrusions)
        const ppeResponse = await axios.get(`${API_BASE_URL}/api/database/count-ppe`);
        console.log("[Dashboard] PPE Response:", ppeResponse.data);
        setPpeCount(ppeResponse.data.count);

        // Fetch actions count
        const actionResponse = await axios.get(`${API_BASE_URL}/api/database/count-actions`);
        console.log("[Dashboard] Actions Response:", actionResponse.data);
        setActionCount(actionResponse.data.count);

        console.log("[Dashboard] Counts successfully fetched!");
      } catch (error) {
        console.error("[Dashboard] Error fetching counts:", error.message);
      }
    };

    fetchCounts();
  }, []);

  return (
    <div className="p-6">
      {/* Greeting Section */}
      <div className="text-4xl font-semibold text-darkblue mb-8">
        {user ? (
          <p>Hi {firstName}, here are your live stats</p>
        ) : (
          <p>Please sign in to view your report</p>
        )}
      </div>

      {/* Cards Section */}
      <div className="grid grid-cols-3 gap-6 mb-10 w-full">
        {/* Card 1 - Collisions Detected */}
        <NavLink
          to="/dashboard/events"
          className="bg-white rounded-lg shadow-lg p-6 text-center h-30"
        >
          <div className="text-2xl font-medium text-gray-700 pb-5">
            Collisions Detected
          </div>
          <div className="text-xl text-red text-bold">{collisionCount}</div>
        </NavLink>

        {/* Card 2 - PPE Failures Detected */}
        <NavLink
          to="/dashboard/nearmiss"
          className="bg-white rounded-lg shadow-lg p-6 text-center h-30"
        >
          <div className="text-2xl font-medium text-gray-700 pb-5">
            PPE Failures Detected
          </div>
          <div className="text-xl text-red text-bold">{ppeCount}</div>
        </NavLink>

        {/* Card 3 - Intrusions Detected */}
        <NavLink
          to="/dashboard/actions"
          className="bg-white rounded-lg shadow-lg p-6 text-center h-30"
        >
          <div className="text-2xl font-medium text-gray-700 pb-5">
            Intrusions Detected
          </div>
          <div className="text-xl text-red text-bold">{ppeCount}</div>
        </NavLink>

        {/* Card 4 - Pending Actions */}
        <NavLink
          to="/dashboard/cameras"
          className="bg-white rounded-lg shadow-lg p-6 text-center h-30"
        >
          <div className="text-2xl font-medium text-gray-700 pb-5">
            Pending Actions
          </div>
          <div className="text-xl text-red text-bold">{actionCount}</div>
        </NavLink>

        {/* Card 5 - Cameras Online */}
        <NavLink
          to="/dashboard/cameras"
          className="bg-white rounded-lg shadow-lg p-6 text-center h-30"
        >
          <div className="text-2xl font-medium text-gray-700 pb-5">
            Cameras Online
          </div>
          <div className="text-xl text-green text-bold">0</div>
        </NavLink>

        {/* Card 6 - Cameras Offline */}
        <NavLink
          to="/dashboard/cameras"
          className="bg-white rounded-lg shadow-lg p-6 text-center h-30"
        >
          <div className="text-2xl font-medium text-gray-700 pb-5">
            Cameras Offline
          </div>
          <div className="text-xl text-red">65</div>
        </NavLink>
      </div>

      <div className="text-4xl font-semibold text-darkblue mb-8">
        <p>Incident Report</p>
      </div>

      {/* Pagination Control - Top */}
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => {}}
          className="bg-gray-200 w-20 h-10 rounded-md hover:bg-gray-300"
        >
          Previous
        </button>
        <span className="text-gray-600">1 / 1</span>
        <button
          onClick={() => {}}
          className="bg-gray-200 w-20 h-10 rounded-md hover:bg-gray-300"
        >
          Next
        </button>
      </div>

      {/* Table Section */}
      <div className="overflow-auto bg-white shadow-lg rounded-lg">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100 border-b">
            <tr>
              <th className="py-4 px-6 text-left">
                <input
                  type="checkbox"
                  className="bg-gray-200 rounded-md"
                />
              </th>
              <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">
                Name
              </th>
              <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">
                Location
              </th>
              <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">
                Incident Description
              </th>
              <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">
                Date - time
              </th>
              <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b hover:bg-gray-50">
              <td className="py-4 px-6">
                <input type="checkbox" className="bg-gray-200 rounded-md" />
              </td>
              <td className="py-4 px-6 text-md text-gray-700">Camera 1</td>
              <td className="py-4 px-6 text-md text-gray-700">Location 1</td>
              <td className="py-4 px-6 text-md text-gray-700">Incident 1</td>
              <td className="py-4 px-6 text-md text-gray-700">Date-time 1</td>
              <td className="py-4 px-6 text-sm text-gray-700 flex space-x-2 opacity-0 hover:opacity-100">
                <button className="text-blue-500">Edit</button>
                <button className="text-red-500">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Dashboard;
