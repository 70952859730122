import React, { useState, useContext } from "react";

// Example User Context
const UserContext = React.createContext();

const UserNameProfile = () => {
  const user = useContext(UserContext);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({ ...user });

  const handleEdit = () => setIsEditing(true);
  const handleSave = () => {
    // Handle save logic here, such as an API call
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="flex justify-center mt-10 px-4">
      <div className="w-full h-[800px] bg-white rounded-lg shadow-xl relative px-10">
        <div className="absolute top-4 right-4">
          {!isEditing && (
            <button
              onClick={handleEdit}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700"
            >
              Edit
            </button>
          )}
        </div>
        <div className="p-8">
          <div className="flex items-center space-x-6 border-b pb-6 mb-6">
            <img
              src={editedUser?.image || "https://via.placeholder.com/150"}
              alt="User Avatar"
              className="w-24 h-24 rounded-full border"
            />
            <div>
              {!isEditing ? (
                <>
                  <h1 className="text-3xl font-bold text-gray-800">
                    {editedUser?.name || "User Name"}
                  </h1>
                  <p className="text-gray-500">ID: {editedUser?.id || "N/A"}</p>
                </>
              ) : (
                <>
                  <input
                    type="text"
                    name="name"
                    value={editedUser.name}
                    onChange={handleChange}
                    className="text-3xl font-bold text-gray-800 border-b border-gray-300 focus:outline-none"
                  />
                  <input
                    type="text"
                    name="id"
                    value={editedUser.id}
                    onChange={handleChange}
                    className="text-gray-500 mt-2 border-b border-gray-300 focus:outline-none"
                  />
                </>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h2 className="text-lg font-bold text-gray-800">Department:</h2>
              {!isEditing ? (
                <p className="text-gray-600">
                  {editedUser?.department || "N/A"}
                </p>
              ) : (
                <input
                  type="text"
                  name="department"
                  value={editedUser.department}
                  onChange={handleChange}
                  className="text-gray-600 border-b border-gray-300 focus:outline-none"
                />
              )}
            </div>
            <div>
              <h2 className="text-lg font-bold text-gray-800">Duties:</h2>
              {!isEditing ? (
                <p className="text-gray-600">
                  {Array.isArray(editedUser?.duties)
                    ? editedUser.duties.join(", ")
                    : "Duties information unavailable or not in array format."}
                </p>
              ) : (
                <textarea
                  name="duties"
                  value={Array.isArray(editedUser.duties) ? editedUser.duties.join(", ") : editedUser.duties}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "duties",
                        value: e.target.value.split(",").map((d) => d.trim()),
                      },
                    })
                  }
                  className="text-gray-600 border-b border-gray-300 focus:outline-none w-full"
                />
              )}
            </div>
            <div>
              <h2 className="text-lg font-bold text-gray-800">Area:</h2>
              {!isEditing ? (
                <p className="text-gray-600">{editedUser?.area || "N/A"}</p>
              ) : (
                <input
                  type="text"
                  name="area"
                  value={editedUser.area}
                  onChange={handleChange}
                  className="text-gray-600 border-b border-gray-300 focus:outline-none"
                />
              )}
            </div>
            <div>
              <h2 className="text-lg font-bold text-gray-800">
                Contact Details:
              </h2>
              {!isEditing ? (
                <>
                  <p className="text-gray-600">
                    <span className="font-medium">Government ID:</span>{" "}
                    {editedUser?.government_id || "N/A"}
                  </p>
                  <p className="text-gray-600">
                    <span className="font-medium">Phone:</span>{" "}
                    {editedUser?.phone || "N/A"}
                  </p>
                  <p className="text-gray-600">
                    <span className="font-medium">Email:</span>{" "}
                    {editedUser?.email || "N/A"}
                  </p>
                  <p className="text-gray-600">
                    <span className="font-medium">Address:</span>{" "}
                    {editedUser?.address || "N/A"}
                  </p>
                </>
              ) : (
                <>
                  <input
                    type="text"
                    name="government_id"
                    value={editedUser.government_id}
                    onChange={handleChange}
                    className="text-gray-600 border-b border-gray-300 focus:outline-none w-full"
                    placeholder="Government ID"
                  />
                  <input
                    type="text"
                    name="phone"
                    value={editedUser.phone}
                    onChange={handleChange}
                    className="text-gray-600 border-b border-gray-300 focus:outline-none w-full mt-2"
                    placeholder="Phone"
                  />
                  <input
                    type="email"
                    name="email"
                    value={editedUser.email}
                    onChange={handleChange}
                    className="text-gray-600 border-b border-gray-300 focus:outline-none w-full mt-2"
                    placeholder="Email"
                  />
                  <input
                    type="text"
                    name="address"
                    value={editedUser.address}
                    onChange={handleChange}
                    className="text-gray-600 border-b border-gray-300 focus:outline-none w-full mt-2"
                    placeholder="Address"
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {isEditing && (
          <div className="absolute bottom-4 right-4">
            <button
              onClick={handleSave}
              className="px-4 py-2 m-10 bg-green text-white rounded-lg shadow hover:bg-green-700"
            >
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserNameProfile;

// Example usage with a UserContext provider
export const App = () => {
  const user = {
    name: "John Doe",
    id: "12345",
    image: "https://via.placeholder.com/150",
    department: "Engineering",
    duties: ["Code", "Review", "Deploy"],
    area: "New York",
    government_id: "A12345",
    phone: "555-1234",
    email: "john.doe@example.com",
    address: "123 Main St, NY",
  };

  return (
    <UserContext.Provider value={user}>
      <UserNameProfile />
    </UserContext.Provider>
  );
};
