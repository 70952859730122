import React, { useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Ensure jwt-decode is installed: npm install jwt-decode
import { UserContext } from "../context/UserContext";

const SignIn = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  // Google Sign-In callback function
  const handleGoogleSignIn = (response) => {
    try {
      const user = jwtDecode(response.credential); // Decode JWT to extract user info
      console.log("Decoded user info:", user);

      // Set user data in context
      setUser({
        name: user.name,
        image: user.picture,
      });

      // Navigate to dashboard after successful sign-in
      navigate("/dashboard");
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  };

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id:
        "365855051009-laor0ibqqv4ianj7fs9g8pp11cka2qn9.apps.googleusercontent.com", // Replace with your actual Google Client ID
      callback: handleGoogleSignIn,
    });
    google.accounts.id.renderButton(
      document.getElementById("googleSignInButton"),
      {
        theme: "outline",
        size: "large",
      }
    );
  }, []);

  // Click handler for Sign In button (for non-Google login)
  const handleSignIn = () => {
    // Placeholder for manual login; redirects to dashboard
    navigate("/dashboard");
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gradient-to-t from-orange to-skyblue px-4 sm:px-8">
      {/* Left side information */}
      <div className="flex flex-col max-w-md lg:max-w-lg xl:max-w-2xl overflow-hidden p-8 w-full lg:w-1/2 mr-0 lg:mr-16 xl:mr-20">
        <div className="text-darkblue text-5xl font-semibold mb-10">
          Contracto AI
        </div>
        <div className="space-y-6">
          <div className="flex items-start space-x-3">
            <span className="text-gray-600 text-3xl">⚙️</span>
            <div>
              <h3 className="font-bold text-xl text-darkblue">Dynamic Efficiency</h3>
              <p className="text-darkblue text-lg">
                Our product seamlessly aligns with your unique requirements,
                enhancing productivity and streamlining your workflow with ease.
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-3">
            <span className="text-gray-600 text-2xl">🛠️</span>
            <div>
              <h3 className="font-bold text-xl text-darkblue">
                Engineered for Excellence
              </h3>
              <p className="text-darkblue text-lg">
                Benefit from exceptional durability designed for the long haul,
                making it a reliable, lasting investment.
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-3">
            <span className="text-gray-600 text-2xl">✨</span>
            <div>
              <h3 className="font-bold text-xl text-darkblue">
                Cutting-Edge Features
              </h3>
              <p className="text-darkblue text-lg">
                Enjoy pioneering functionality that anticipates and adapts to
                your changing needs, keeping you steps ahead with every use.
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-3">
            <span className="text-gray-600 text-2xl">👍</span>
            <div>
              <h3 className="font-bold text-xl text-darkblue">
                Exceptional User Experience
              </h3>
              <p className="text-darkblue text-lg">
                Easily incorporate our product into your daily life with a
                user-friendly, intuitive interface that enhances every
                interaction.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Right side form */}
      <div className="flex flex-col max-w-xs w-[400px] h-[500px] md:max-w-sm lg:max-w-md bg-white rounded-xl shadow-lg overflow-hidden p-6">
        <h2 className="text-3xl font-bold text-gray-800 mb-8">Sign in</h2>
        <div className="space-y-3">
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            type="email"
            placeholder="your@email.com"
            sx={{
              "& .MuiInputLabel-root": { fontSize: 16 },
              "& .MuiInputBase-input": { fontSize: 16 },
            }}
          />
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            type="password"
            sx={{
              "& .MuiInputLabel-root": { fontSize: 16 },
              "& .MuiInputBase-input": { fontSize: 16 },
            }}
          />
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Checkbox color="primary" />
              <span className="text-gray-600 text-base">Remember me</span>
            </div>
            <a
              href="#"
              className="text-blue-500 text-base"
              onClick={() => navigate("#")}
            >
              Forgot Password?
            </a>
          </div>
          <div className="flex flex-row justify-center space-x-10 my-5">
          <h3>New Here?</h3>
          <a
              href="#"
              className="text-blue-500 text-base"
              onClick={() => navigate("/signup")}
            >
              Register Here
            </a>
          </div>
          <Button
            variant="contained"
            fullWidth
            style={{
              backgroundColor: "#052d41",
              color: "#fff",
              fontSize: "16px",
            }}
            onClick={handleSignIn}
          >
            Sign in
          </Button>
          <div className="flex items-center my-3">
            <hr className="flex-grow border-gray-300" />
            <span className="mx-3 text-gray-600 text-base">or</span>
            <hr className="flex-grow border-gray-300" />
          </div>

          {/* Google Sign-In Button */}
          <div id="googleSignInButton" className="w-full flex justify-center"></div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
