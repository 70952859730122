import React, { useState } from 'react';


const API_BASE_URL = 'https://api-e5tzoauvmq-uc.a.run.app'; 
const AddEmployeePanel = ({ onClose, onSave, departments, areas }) => {
  const [employee, setEmployee] = useState({
    id: '',
    firstName: '',
    lastName: '',
    department: '',
    area: '',
    phone: '',
    email: '',
    address: '',
    governmentId: '',
    photo: null,
  });

  const handleInputChange = (field, value) => {
    setEmployee((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    try {
      // Validate form fields before submission
      if (
        !employee.firstName ||
        !employee.lastName ||
        !employee.department ||
        !employee.area ||
        !employee.phone ||
        !employee.email ||
        !employee.address ||
        !employee.governmentId
      ) {
        alert('All fields are required. Please fill in all the details.');
        return;
      }
  
      let profilePictureUrl = null;
  
      // Upload profile picture if provided
      if (employee.photo) {
        const formData = new FormData();
        formData.append('file', employee.photo); // Attach the photo file
        console.log('Uploading file:', employee.photo);
  
        // No need to manually set Content-Type; browser handles it
        const uploadResponse = await fetch("https://api-e5tzoauvmq-uc.a.run.app/api/storage/upload-profile-picture", {
          method: 'POST',
          body: formData, // Attach the FormData object
        });
  
        if (!uploadResponse.ok) {
          const errorText = await uploadResponse.text();
          console.error(`Failed to upload profile picture: ${errorText}`);
          throw new Error(`Failed to upload profile picture: ${uploadResponse.statusText}`);
        }
  
        const uploadData = await uploadResponse.json();
        if (!uploadData.success) {
          throw new Error(uploadData.message || 'Unknown error during profile picture upload');
        }
  
        profilePictureUrl = uploadData.publicURL;
      }
  
      // Save employee record
      const saveResponse = await fetch("https://api-e5tzoauvmq-uc.a.run.app/api/database/add-employee", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          first_name: employee.firstName,
          last_name: employee.lastName,
          department: employee.department,
          area: employee.area,
          phone: employee.phone,
          email: employee.email,
          address: employee.address,
          government_id: employee.governmentId,
          profile_picture_url: profilePictureUrl,
        }),
      });
  
      if (!saveResponse.ok) {
        throw new Error(`Failed to save employee: ${saveResponse.statusText}`);
      }
  
      const saveData = await saveResponse.json(); // `saveData` is now correctly initialized here
      if (!saveData.success) {
        throw new Error(saveData.message || 'Unknown error during employee save');
      }
  
      alert('Employee saved successfully!');
      onSave();
      onClose();
    } catch (err) {
      console.error('Error saving employee:', err.message); // Debug logging
      alert(`Failed to save employee. Please try again.\nError: ${err.message}`);
    }
  };
  
  
    
     
       
    const handlePhotoUpload = (e) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        console.log('Uploading file:', file); // Add this
        const previewUrl = URL.createObjectURL(file); // Generate preview URL
    
        setEmployee((prev) => ({
          ...prev,
          photo: file,         // Store the file for upload
          photoPreview: previewUrl, // Store the preview URL for display
        }));
      }
    };
    
  return (
    <div
      className="absolute right-0 top-[55px] h-[calc(100%-55px)] w-full sm:w-2/3 md:w-1/2 lg:w-1/3 bg-gray-50 shadow-lg rounded-xl p-8 flex flex-col space-y-8"
    >
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-2xl font-bold text-darkblue">ADD NEW  Employee</h2>
        <button onClick={onClose} className="text-gray-400 hover:text-gray-600 text-xl">
          ✖
        </button>
      </div>

      {/* Photo and Employee ID */}
      <div className="flex items-center space-x-6 mb-8">
        <div className="relative w-24 h-24 bg-gray-300 rounded-full flex items-center justify-center">
        {employee.photoPreview ? (
            <img
                src={employee.photoPreview} // Use the preview URL
                alt="Employee"
                className="w-full h-full object-cover rounded-full"
            />
            ) : (
            <span className="text-gray-500 text-2xl">👤</span>
        )}

          <label
            htmlFor="photo-upload"
            className="absolute bottom-0 right-0 bg-skyblue text-white rounded-full p-2 cursor-pointer"
          >
            ➕
          </label>
          <input
            id="photo-upload"
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handlePhotoUpload}
          />
        </div>
        <input
          type="text"
          value={employee.id}
          onChange={(e) => handleInputChange('id', e.target.value)}
          placeholder="Employee ID"
          className="flex-1 p-3 border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
        />
      </div>

      {/* Name Fields */}
      <div className="flex space-x-6 mb-8">
        <input
          type="text"
          value={employee.firstName}
          onChange={(e) => handleInputChange('firstName', e.target.value)}
          placeholder="First Name"
          className="flex-1 p-3 border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
        />
        <input
          type="text"
          value={employee.lastName}
          onChange={(e) => handleInputChange('lastName', e.target.value)}
          placeholder="Last Name"
          className="flex-1 p-3 border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
        />
      </div>

      {/* Dropdowns for Department and Area */}
      <div className="flex space-x-6 mb-8">
        <select
          value={employee.department}
          onChange={(e) => handleInputChange('department', e.target.value)}
          className="flex-1 p-3 border border-gray-300 rounded-md bg-transparent focus:outline-none focus:border-skyblue"
        >
          <option value="">Select Department</option>
          {departments.map((dept, index) => (
            <option key={index} value={dept}>
              {dept}
            </option>
          ))}
        </select>
        <select
          value={employee.area}
          onChange={(e) => handleInputChange('area', e.target.value)}
          className="flex-1 p-3 border border-gray-300 rounded-md bg-transparent focus:outline-none focus:border-skyblue"
        >
          <option value="">Select Area</option>
          {areas.map((area, index) => (
            <option key={index} value={area}>
              {area}
            </option>
          ))}
        </select>
      </div>

      {/* Phone and Email */}
      <div className="flex space-x-6 mb-8">
        <input
          type="tel"
          value={employee.phone}
          onChange={(e) => handleInputChange('phone', e.target.value)}
          placeholder="Phone Number"
          className="flex-1 p-3 border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
        />
        <input
          type="email"
          value={employee.email}
          onChange={(e) => handleInputChange('email', e.target.value)}
          placeholder="Email Address"
          className="flex-1 p-3 border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
        />
      </div>

      {/* Government ID */}
      <div className="mb-8">
        <input
          type="text"
          value={employee.governmentId}
          onChange={(e) => handleInputChange('governmentId', e.target.value)}
          placeholder="Government ID No"
          className="w-full p-3 border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
        />
      </div>

      {/* Address */}
      <div className="mb-20">
        <input
          type="text"
          value={employee.address}
          onChange={(e) => handleInputChange('address', e.target.value)}
          placeholder="Address"
          className="w-full p-3 mt-20 mb-10 border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
        />
      </div>

      {/* Save Button */}
      <div className='flex justify-center'>
        <button
            onClick={handleSave}
            className="bg-skyblue text-white text-md align-center w-full py-4 rounded-xl shadow-md hover:bg-blue-500"
        >SAVE
        </button>
      </div>

    </div>
  );
};

export default AddEmployeePanel;
