import React from "react";
import privacysecurity from "../Assets/privacysecurity.svg"; // Replace this with your actual SVG file
import privacysecurityimg from "../Assets/privacysecurityimg.jpeg"; // Replace with the actual image path

const PrivacySecuirty = () => {
  return (
    <section className="w-full bg-white py-20 flex justify-center">
      <div className="flex flex-col lg:flex-row items-center justify-around w-full mx-auto px-20 space-y-8 lg:space-y-0 lg:space-x-40">
        
        {/* Left column with text content and buttons */}
        <div className="lg:w-[40%] min-w-[300px] space-y-6 text-left">
          <h1 className="text-5xl font-bold leading-tight text-darkblue">
          Unwavering Commitment to Privacy & Security
          </h1>
          <p className="text-lg max-w-md text-darkblue">
          We prioritize your data and trust, designing our processes and products to uphold the highest standards of security and privacy.          </p>
        </div>

        {/* Right column with image and overlay icon */}
        <div className="relative lg:w-[40%] flex justify-center lg:justify-center">
          <img 
            src={privacysecurityimg} 
            className="w-full h-[400px] rounded-2xl shadow-lg object-fit" // Increased width, removed rounded corners
            alt="Privacy"
          />
        </div>
      </div>
    </section>
  );
};

export default PrivacySecuirty;
