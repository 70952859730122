import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";


const API_BASE_URL = 'https://api-e5tzoauvmq-uc.a.run.app'; 
const SignUp = () => {
  const [animatedText, setAnimatedText] = useState("");
  const tagline = "Your Partner in Smart Solutions..."; // Tagline for animation
  const navigate = useNavigate();

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < tagline.length) {
        setAnimatedText((prev) => (prev || "") + tagline[index]); // Ensure "prev" is always a string
        index++;
      } else {
        clearInterval(interval);
      }
    }, 120); // Adjust typing speed
    return () => clearInterval(interval); // Clean up interval
  }, []);

  const handleSignUp = async () => {
    try {
      const email = document.querySelector('[label="Email"]').value;
      const password = document.querySelector('[label="Password"]').value;
      const companyName = document.querySelector('[label="Company Name"]').value;
      const location = document.querySelector('[label="Location"]').value;

      const response = await fetch(`https://api-e5tzoauvmq-uc.a.run.app/api/database/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          company_name: companyName,
          location: location,
        }),
      });

      const data = await response.json();

      if (data.success) {
        alert("You have successfully signed up!");
        navigate("/signin"); // Redirect to Sign In page
      } else {
        alert("Failed to sign up: " + data.message);
      }
    } catch (error) {
      console.error("Error during sign up:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gradient-to-t from-orange to-skyblue px-4 sm:px-8">
      {/* Left side information */}
      <div className="flex flex-col max-w-md lg:max-w-lg xl:max-w-2xl overflow-hidden p-8 w-full lg:w-1/2 mr-0 lg:mr-16 xl:mr-20">
        <div className="text-darkblue text-5xl font-semibold mb-6">
          Contracto AI
        </div>
        <div className="text-darkblue text-3xl font-semibold h-10">
          {animatedText}
        </div>
      </div>

      {/* Right side form */}
      <div className="flex flex-col max-w-xs w-[420px] h-[500px] md:max-w-sm lg:max-w-md bg-white rounded-xl shadow-lg overflow-hidden p-6">
        <h2 className="text-3xl font-bold text-gray-800 mb-8">Sign Up</h2>
        <div className="space-y-5">
          {/* Email Field */}
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            type="email"
            placeholder="your@email.com"
            sx={{
              "& .MuiInputLabel-root": { fontSize: 16 },
              "& .MuiInputBase-input": { fontSize: 16 },
            }}
          />
          {/* Password Field */}
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            type="password"
            sx={{
              "& .MuiInputLabel-root": { fontSize: 16 },
              "& .MuiInputBase-input": { fontSize: 16 },
            }}
          />
          {/* Company Name Field */}
          <TextField
            label="Company Name"
            variant="outlined"
            fullWidth
            type="text"
            placeholder="Your company name"
            sx={{
              "& .MuiInputLabel-root": { fontSize: 16 },
              "& .MuiInputBase-input": { fontSize: 16 },
            }}
          />
          {/* Location Field */}
          <TextField
            label="Location"
            variant="outlined"
            fullWidth
            type="text"
            placeholder="Your location"
            sx={{
              "& .MuiInputLabel-root": { fontSize: 16 },
              "& .MuiInputBase-input": { fontSize: 16 },
            }}
          />
          {/* Sign Up Button */}
          <Button
            variant="contained"
            fullWidth
            style={{
              backgroundColor: "#052d41",
              color: "#fff",
              fontSize: "16px",
            }}
            onClick={handleSignUp}
          >
            Sign Up
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
