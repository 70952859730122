import React, { useContext, useState, useEffect} from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AddActionPanel from '../Widgets/AddActionPanel';
import ViewActionsPanel from '../Widgets/ViewActionPanel'; // Import the new panel
import AddCollisonPanel from '../Widgets/AddCollisionPanel';
import { UserContext } from '../context/UserContext';



const ItemType = 'CARD';
const API_BASE_URL = 'https://api-e5tzoauvmq-uc.a.run.app';


// Helper function to render media based on type
// Part: Update Card Component to handle media_url rendering
// Helper function to render media based on type
const renderMedia = (type, media_url) => {
  if (media_url) {
    if (type === 'image') {
      return (
        <img
          src={media_url}
          alt="Preview"
          className="w-16 h-16 object-cover rounded-lg border border-gray-300"
        />
      );
    } else if (type === 'video') {
      return (
        <video
          src={media_url}
          className="w-16 h-16 object-cover rounded-lg border border-gray-300"
          controls
        />
      );
    } else if (type === 'audio') {
      return (
        <audio
          src={media_url}
          className="w-16 h-16 rounded-lg border border-gray-300"
          controls
        />
      );
    } else if (type === 'document') {
      return (
        <div className="w-16 h-16 bg-blue-100 rounded-lg flex items-center justify-center">
          📄
        </div>
      );
    }
    return (
      <div className="w-16 h-16 bg-gray-200 rounded-lg flex items-center justify-center">
        📎
      </div>
    );
  }

  switch (type) {
    case 'image':
      return <div className="w-16 h-16 bg-blue-100 rounded-lg flex items-center justify-center">🖼️</div>;
    case 'video':
      return <div className="w-16 h-16 bg-blue-100 rounded-lg flex items-center justify-center">🎥</div>;
    case 'audio':
      return <div className="w-16 h-16 bg-blue-100 rounded-lg flex items-center justify-center">🎵</div>;
    case 'document':
      return <div className="w-16 h-16 bg-blue-100 rounded-lg flex items-center justify-center">📄</div>;
    default:
      return <div className="w-16 h-16 bg-gray-200 rounded-lg flex items-center justify-center">📎</div>;
  }
};



// Card Component
const Card = ({ id, title, column, moveCard, type, priority, createdDate, dueDate, description, media_url, onClick }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemType,
    item: { id, column },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const priorityColor = {
    urgent: 'bg-red',
    high: 'bg-orange',
    medium: 'bg-yellow',
    low: 'bg-green',
  };

  return (
    <div
      ref={drag}
      className={`bg-white p-4 mb-4 shadow-lg rounded-md ${isDragging ? 'opacity-50' : 'opacity-100'}`}
      onClick={() => onClick({ id, title, column, type, priority, createdDate, dueDate, description, media_url })} // Pass media_url explicitly
    >
      {/* Media Container */}
      <div className="mb-4 flex items-center space-x-3">
        {renderMedia(type, media_url)}
        <div>
          <p className="text-xl font-bold text-gray-800">{title}</p>
        </div>
      </div>

      {/* Priority and Dates */}
      <div className="mb-2 flex items-center space-x-2">
        <span
          className={`px-3 py-1 rounded-full text-white text-xs font-semibold ${
            priorityColor[priority] || 'bg-gray-400'
          }`}
        >
          {priority}
        </span>
        <span className="text-xs text-gray-500">Created: {createdDate}</span>
        <span className="text-xs text-gray-500">Due: {dueDate || 'N/A'}</span>
      </div>

      {/* Description */}
      <p className="text-sm text-gray-700">{description}</p>
    </div>
  );
};


// Column Component for Drag-and-Drop
const Column = ({ title, cards, moveCard, column, onCardClick, empty }) => {
  const [, drop] = useDrop({
    accept: ItemType,
    drop: (item) => moveCard(item.id, item.column, column),
  });

  return (
    <div
      ref={drop}
      className={`flex-1 bg-gray-100 border-dashed border-2 border-lightskyblue p-4 shadow-lg rounded-lg min-h-[${empty ? '200px' : '400px'}]`}
    >
      <h2 className="text-lg font-semibold text-gray-600 mb-4">{title}</h2>
      {cards.map((card) => (
        <Card
          key={card.id}
          id={card.id}
          title={card.title}
          column={card.column}
          type={card.type}
          priority={card.priority}
          createdDate={card.createdDate}
          dueDate={card.dueDate}
          description={card.description}
          media_url={card.media_url} // Pass media_url explicitly
          moveCard={moveCard}
          onClick={onCardClick}
        />

      ))}
    </div>
  );
};

// Table Section Component with collapsible functionality
const TableSection = ({ title, data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState(new Set());

  const toggleSection = () => setIsOpen(!isOpen);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRows(new Set(data.map((item) => item.id)));
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prev) => {
      const newSelectedRows = new Set(prev);
      if (newSelectedRows.has(id)) {
        newSelectedRows.delete(id);
      } else {
        newSelectedRows.add(id);
      }
      return newSelectedRows;
    });
  };

  return (
    <div className="mb-6">
      <div className="flex justify-between items-center cursor-pointer" onClick={toggleSection}>
        <h3 className="text-lg font-semibold text-darkblue">{title}</h3>
        <span className="text-lg">{isOpen ? '▲' : '▼'}</span>
      </div>
      {isOpen && (
        <div className="overflow-auto bg-white shadow-lg rounded-lg mt-4">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-100 border-b">
              <tr>
                <th className="py-4 px-6 text-left">
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedRows.size === data.length}
                    className="bg-gray-200 rounded-md"
                  />
                </th>
                <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Title</th>
                <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Date Created</th>
                <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Date Due</th>
                <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Priority</th>
                <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Description</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id} className="border-b hover:bg-gray-50">
                  <td className="py-4 px-6">
                    <input
                      type="checkbox"
                      checked={selectedRows.has(item.id)}
                      onChange={() => handleSelectRow(item.id)}
                      className="bg-gray-200 rounded-md"
                    />
                  </td>
                  <td className="py-4 px-6 text-md text-gray-700">{item.title}</td>
                  <td className="py-4 px-6 text-md text-gray-700">{item.createdDate}</td>
                  <td className="py-4 px-6 text-md text-gray-700">{item.dueDate}</td>
                  <td className="py-4 px-6 text-md text-gray-700">{item.priority}</td>
                  <td className="py-4 px-6 text-md text-gray-700">{item.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

// Actions Component
const Actions = () => {
  const { user } = useContext(UserContext);

  // const dummyCards = [
  //   { id: 1, title: 'Collision Alert Review', column: 'toBeReviewed', type: 'video', priority: 'urgent', createdDate: '2024-11-18', dueDate: '2024-11-19', description: 'Review recent collision alerts captured by camera #23' },
  //   { id: 2, title: 'Safety Document Update', column: 'toBeReviewed', type: 'document', priority: 'high', createdDate: '2024-11-18', dueDate: '2024-11-20', description: 'Update safety protocol document with new collision prevention measures' },
  //   { id: 3, title: 'Near Miss Review', column: 'toBeReviewed', type: 'document', priority: 'medium', createdDate: '2024-11-18', dueDate: '2024-11-20', description: 'Update safety protocol document with new collision prevention measures' },
  //   { id: 4, title: 'Safety Protocol Meeting', column: 'toBeReviewed', type: 'document', priority: 'low', createdDate: '2024-11-18', dueDate: '2024-11-20', description: 'Update safety protocol document with new collision prevention measures' },
  //   // Additional cards...
  // ];

  

  const [cards, setCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isCollisionPanelOpen, setIsCollisionPanelOpen] = useState(false);  // New state for Collision panel
  const [selectedCard, setSelectedCard] = useState(null);

  // Part: Update fetchActions function in Actions component
  const fetchActions = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/database/fetch-actions`); // Ensure this URL matches your backend
      if (!response.ok) {
        throw new Error(`Failed to fetch actions: ${response.statusText}`);
      }

      const { data } = await response.json();
      console.log('Fetched Actions:', data);

      const formattedCards = data.map((action) => ({
        id: action.id,
        title: action.title,
        column: action.column || 'toBeReviewed',
        type: action.type || 'image',
        priority: action.priority,
        createdDate: action.created_at?.split('T')[0] || '',
        deadline_datetime: action.deadline_datetime?.split('T')[0] || '', // Ensure due_date is displayed
        description: action.description,
        media_url: action.media_url || '',
      }));

      setCards(formattedCards);
    } catch (error) {
      console.error('Error fetching actions:', error.message);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    fetchActions();
  }, []);


  const moveCard = (id, fromColumn, toColumn) => {
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.id === id
          ? { ...card, column: toColumn, createdDate: new Date().toISOString().split('T')[0] }
          : card
      )
    );
  };

  const updateCard = (updatedCard) => {
    setCards((prevCards) =>
      prevCards.map((card) => (card.id === updatedCard.id ? updatedCard : card))
    );
  };

  const openCardDetails = (card) => {
    setSelectedCard(card);
    setIsPanelOpen(true);
  };

  const closePanel = () => {
    setSelectedCard(null);
    setIsPanelOpen(false);
  };

  const columns = {
    toBeReviewed: 'TO BE REVIEWED',
    reviewed: 'REVIEWED',
    completed: 'COMPLETED',
    archived: 'ARCHIVED',
  };

  const today = new Date();
  const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const startOfYesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
  const startOfThisWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  const startOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const tableData = {
    today: cards.filter((card) => card.column === 'completed' && new Date(card.createdDate) >= startOfToday),
    yesterday: cards.filter((card) => {
      const createdDate = new Date(card.createdDate);
      return createdDate >= startOfYesterday && createdDate < startOfToday;
    }),
    thisWeek: cards.filter((card) => {
      const createdDate = new Date(card.createdDate);
      return createdDate >= startOfThisWeek && createdDate < startOfYesterday;
    }),
    thisMonth: cards.filter((card) => {
      const createdDate = new Date(card.createdDate);
      return createdDate >= startOfThisMonth && createdDate < startOfThisWeek;
    }),
  };

  if (isLoading) {
    return <div className="text-center py-4 text-lg text-gray-500">Loading actions...</div>;
  }
  

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="p-8 relative">
        {/* Header */}
        <h1 className="text-4xl font-semibold text-darkblue mb-8">
          Hi {user ? user.name : 'Guest'}, here are your Action items
        </h1>

        {/* Search Bar and Buttons */}
        <div className="flex items-center justify-between mb-6">
          <div className="w-1/3">
            <input
              type="text"
              placeholder="Search"
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
            />
          </div>
           <div className="flex space-x-4">
            {/* <button
              onClick={() => setIsCollisionPanelOpen(true)} // Open Collision Panel
              className="bg-orange text-white px-6 py-2 rounded-lg shadow-md hover:bg-red-700"
            >
              + Add Collision
            </button> */}
            <button
              onClick={() => setIsPanelOpen(true)} // Open Action Panel
              className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-700"
            >
              + Add Action
            </button>
          </div> 
        </div>

        {/* Drag-and-Drop Columns */}
        <div className="flex space-x-4 mb-8">
          {Object.keys(columns).map((columnKey) => (
            <Column
              key={columnKey}
              title={columns[columnKey]}
              column={columnKey}
              cards={cards.filter((card) => card.column === columnKey)}
              moveCard={moveCard}
              onCardClick={openCardDetails}
            />
          ))}
        </div>

        {/* Timeline Segregated Table */}
        <TableSection title="Today" data={tableData.today} />
        <TableSection title="Yesterday" data={tableData.yesterday} />
        <TableSection title="This Week" data={tableData.thisWeek} />
        <TableSection title="This Month" data={tableData.thisMonth} />

        {/* Add Action Panel */}
        {isPanelOpen && !selectedCard && (
          <AddActionPanel
            onClose={() => {
              setIsPanelOpen(false);
              fetchActions(); // Refresh actions after adding a new one
            }}
          />
        )}

        {isPanelOpen && selectedCard && (
          <ViewActionsPanel
            card={selectedCard}
            onClose={closePanel}
            updateCard={updateCard}
            moveCard={moveCard}
          />
        )}

        {/* Add Collision Panel */}
        {isCollisionPanelOpen && (
          <div className="fixed top-0 right-0 h-full w-1/3 bg-white shadow-lg p-4">
            <AddCollisonPanel onClose={() => setIsCollisionPanelOpen(false)} />
          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default Actions;
